<template>
  <div class="hello">
      <div id='container'>
        <div id='DmanTip' class="position-item"  >
          <div id='date'>
            Fecha: 18.03.2021 14:05:53 UTC
          </div>
          <div id='tip'>
            "oh, it is not pumping the first second you gave it" - new Premium noob
                "hold it patiently" - Mr. Y
                "Hey bro, tell them to look at it now - AUCTION" - Mr. Y to me.
                Go look at AUCTION now.
                BLOCKCHAIN WHISPERS GEM - 100% win rate this year so far.
                GOOD JOB FOR JOINING BROTHER, GOOD JOB!
          </div>
      </div>
        <div id='DmanTip' class="position-item"  >
          <div id='date'>
            Fecha:  12.03.2021 18:26:28 UTC
          </div>
          <div id='tip'>
            Auction - it's a gem. It went through some rough path, and is in good area, however at this stage because of that rough path I put it in gem- status. New guys, you can buy into it now, it's wonderful price, and it's great fundamentals. Currently sentiment is weakish due to some mistake they did, and we will give the team a chance. Mr. Y is actively on it.
          </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AUCTION',
  data () {
    return {
    }
  },
  methods: {
  },
}

</script>
